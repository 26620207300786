.appApiTest {
  color: white;
  background-color: #100649;
  min-height: 100vh;
  padding-top: 140px;
}

.App {
  width: 100%;
  height: 100%;
}

.relative {
  position: relative;
}

.ShipApp,
.AccountApp {
  min-height: 80vh;
}

.slick-prev:before,
.slick-next:before {
  font-size: 34px !important;
  filter: drop-shadow(0px 0px 4px #001445);
}

.appHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #00003e;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 30%);
  border-bottom: 1px solid rgb(255 255 255 / 25%);
  padding: 20px 0px;
}

.appFooter {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #00003e;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 30%);
  border-top: 1px solid rgb(255 255 255 / 25%);
  padding: 20px 0px;
  text-align: center;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.navbarSize {
  height: 100px;
}

.flexCenter {
  display: flex;
}

.boxMargin {
  margin: 0px 28px;
}

.alignItemsCenter {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flexGrow1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flexLogo {
  font-weight: 600;
  font-size: 22px;
}

.flexMenu {
  margin-right: 60px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  flex-flow: row nowrap;
}

.menuList {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  cursor: pointer;
}

.menuListActive {
  color: #00fadf;
}

.menuList:hover,
.menuList:active {
  color: #00fadf;
}

.btnAccount {
  position: relative;
  font-weight: 600;
  /* font-size: 18px; */
  background: #21156e;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 40px;
  font-weight: 400;
  filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 80%));
}

.btnAccount:hover {
  top: 2px;
  background: #322392;
}

.borderAccountRight {
  border-right: 1px solid #32286d;
}

.textAccountColor {
  font-weight: bold;
  color: #00fadf;
}

.textAccountUnit {
  font-weight: 400;
  color: white;
}

.logoMetamaskSize {
  width: 30px;
}

.textAccountPadding {
  padding: 0px 8px;
}

/* End Effect */

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (max-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
